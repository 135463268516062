import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/loading2.json';
import LogoGlitch from '../Navbar/LogoGlitch';

const BackgroundContainer = styled.div<any>`
  background: #000;
  width: 100%;
  height: ${(props) => props.height}px;
  position: absolute;
  z-index: -1;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px); /* apply the blur */
    pointer-events: none; /* make the overlay click-through */
  }
`;

const animate = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
`;

const CirclesContainer = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Circle = styled.li`
  position: absolute;
  display: block;
  list-style: none;
  background: rgba(255, 255, 255, 0.2);
  animation: ${animate} 25s linear infinite;
  bottom: -150px;
`;
const AnimationSection = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const AnimationSectionWrapper = styled.div<any>`
  margin: 0 auto;
  background: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr 1fr;
  display: ${(props) => (props.loading ? 'grid' : 'none')};
`;

const Background = (props: any) => {
  const { color } = props;
  const [height, setHeight] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [loading2, setLoading2] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHeight(document.body.scrollHeight);
      setLoading(false);
    }, 500);

    setTimeout(() => {
      setHeight(document.body.scrollHeight);
      setLoading2(false);
    }, 500);
  }, []);

  return (
    <>
      {loading2 ? (
        <AnimationSectionWrapper loading={+true}>
          <AnimationSection>
            {/* <Lottie animationData={loadingAnimation} loop={true} /> */}
            <LogoGlitch big={true} />
          </AnimationSection>
        </AnimationSectionWrapper>
      ) : (
        <>
          <AnimationSectionWrapper loading={+loading}>
            <AnimationSection>
              <Lottie animationData={loadingAnimation} loop={true} />
            </AnimationSection>
          </AnimationSectionWrapper>
          <BackgroundContainer height={height} color={color}>
            <CirclesContainer>
              {Array.from({ length: Math.ceil(height / 120) }).map((_, index) => (
                <Circle
                  key={index}
                  style={{
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    height: `${Math.random() * 20 + 10}px`,
                    width: `${Math.random() * 20 + 10}px`,
                    borderRadius: `100px`,
                    animationDelay: `${Math.random() * 10}s`,
                  }}
                />
              ))}
            </CirclesContainer>
          </BackgroundContainer>
        </>
      )}
    </>
  );
};

export default Background;
