import React from 'react';
import { styled } from 'styled-components';
import Lottie from 'lottie-react';
import faq from '../../assets/animations/ai.json';
import Accordation from '../../components/Accordation';
const Container = styled.div`
  padding: 20px;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 200px;
  max-width: 1300px;
  background: transparent;

  @media (max-width: 768px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;
const ContainerInner = styled.div`
  text-align: left;
  margin: 0 auto;
  background: transparent;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const Child = styled.div`
  display: flex;
  background: transparent;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: end;
`;
const AnimationSection = styled.div`
  background: transparent;
  position: relative;
  margin: 0 auto;
`;
export default function FAQ() {
  return (
    <Container>
      <ContainerInner>
        <Child>
          <Accordation />
        </Child>
        <Child>
          <AnimationSection>
            <Lottie animationData={faq} loop={true} />
          </AnimationSection>
        </Child>
      </ContainerInner>
    </Container>
  );
}
