import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { accordionList, accordionListBot } from '../../api/Reviews';

// Define styled-components for your CSS
const Container = styled.div`
  min-height: 100%;
  transition: 0.5s;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
`;

const AccordionWrapper = styled.div`
  width: 100%;
`;

const AccordionTitle = styled.div<any>`
  padding: 20px;
  cursor: pointer;
  color: #444;
  position: relative;
  font-size: 18px;
  margin-bottom: -1px;
  text-align: left;
  font-weight: 600;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #f0f0f0;
    color: #333;
  }

  ${(props: any) =>
    props.isex &&
    css`
      background-color: #007bff;
      color: #fff;
    `}
`;

const AccordionContent = styled.div<any>`
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.5s ease, opacity 0.5s ease;
  padding: 0 20px;
  background: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 5px 5px;

  ${(props) =>
    props.isex &&
    css`
      height: auto;
      opacity: 1;
      padding: 15px 20px;
    `}

  p {
    margin: 0;
    background: transparent;
    color: #666;
  }
`;

const AccordionContainer = styled.div<any>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

interface AccordionProps {
  title: string;
  isex: boolean;
  answer: string;
  onClick: () => void;
}

const Accordion: React.FC<AccordionProps> = ({ title, isex, answer, onClick }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isex) {
      contentRef.current!.style.height = `${contentRef.current!.scrollHeight}px`;
    } else {
      contentRef.current!.style.height = `0px`;
    }
  }, [isex]);

  return (
    <AccordionContainer>
      <AccordionTitle isex={isex} onClick={onClick}>
        {title}
      </AccordionTitle>
      <AccordionContent ref={contentRef} isex={isex}>
        <p>{answer}</p>
      </AccordionContent>
    </AccordionContainer>
  );
};

const Accordation: React.FC = (props: any) => {
  const { isbot } = props;
  const [index, setIndex] = useState(-1);

  const toggle = (i: number) => () => {
    setIndex((index) => (i === index ? -1 : i));
  };

  return (
    <Container>
      <Title>Frequently Asked Questions</Title>

      <AccordionWrapper>
        {(isbot ? accordionListBot : accordionList).map((item, i) => (
          <Accordion
            key={i}
            title={item.title}
            answer={item.answer}
            onClick={toggle(i)}
            isex={index === i}
          />
        ))}
      </AccordionWrapper>
    </Container>
  );
};

export default Accordation;
