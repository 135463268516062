import Background from '../../components/Background';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Fab from '../../components/Fab';
import { useSearchParams } from 'react-router-dom';
import ServicePackages from './ServicePackages';
import HeroSection from './HeroSection';
import Work from './Work';
import ReviewSlider from '../../components/ReviewSliderSwiper';

export default function Service() {
  const service: any = useSearchParams()[0].get('service');

  return (
    <div>
      <Background />
      <Navbar />
      <HeroSection service={service} />
      <ServicePackages service={service} />
      <ReviewSlider />
      <Work service={service} />
      <Footer />
      <Fab />
    </div>
  );
}
