import React, { useEffect } from 'react';
import { lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AllWorks from './pages/Home/AllWork';
import ReactGA from 'react-ga4';
import { REF } from './api/ref';
import Service from './pages/Service';
const Project = lazy(() => import('./pages/Project'));
const Bots = lazy(() => import('./pages/Bots'));
const BotsWork = lazy(() => import('./pages/Bots/AllWork'));
const GamesWork = lazy(() => import('./pages/Games'));
const GameBots = lazy(() => import('./pages/GameBots'));
const WebsitesWork = lazy(() => import('./pages/Website'));
const TermsOfService = lazy(() => import('./pages/TermsofService'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const BriefMaker = lazy(() => import('./pages/Brief'));

ReactGA.initialize('G-Z3Z1CEEL26');

function App() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referal = urlParams.get('ref');
    if (referal) {
      ReactGA.event({
        category: 'User',
        action: 'Referal',
        label: `Referal from ${REF[referal]}`,
      });
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/work" Component={AllWorks} />
        <Route path="/bots" Component={Bots} />
        <Route path="/bots/work" Component={BotsWork} />
        <Route path="/websites/work" Component={WebsitesWork} />
        <Route path="/games/work" Component={GamesWork} />
        <Route path="/bots/gameBots" Component={GameBots} />
        <Route path="/project/:name" Component={Project} />
        <Route path="/service" Component={Service} />
        <Route path="/termsofservice" Component={TermsOfService} />
        <Route path="/privacypolicy" Component={PrivacyPolicy} />
        <Route path="/brief" Component={BriefMaker} />
      </Routes>
    </Router>
  );
}

export default App;
