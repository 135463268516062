// AboutUs.tsx
import React from 'react';
import styled from 'styled-components';
import me from '../../assets/Me/me.webp';
import me2 from '../../assets/Me/me2.webp';

// Styled Components
const Container = styled.div`
  padding: 20px;
  border-radius: 10px;
  max-width: 1300px;
  min-height: 700px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 46px;
  text-align: center;
  color: #fff;
  margin-bottom: 60px;
`;

const Team = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const Member = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MemberImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

const MemberName = styled.div`
  color: #fff;
  margin-top: 10px;
  font-size: 24px;
`;

const MemberRole = styled.p`
  color: #fff;
  margin-top: 5px;
`;

const Description = styled.p`
  font-size: 22px;
  line-height: 1.5;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const AboutUs: React.FC = () => {
  return (
    <Container>
      <Title>Meet the Team</Title>
      <Description>
        Hidanz Solutions is a leading developer firm specializing in diverse digital services. Our
        team comprises experienced professionals who are passionate about delivering innovative
        solutions that empower businesses and their communities. Our commitment to quality,
        transparency, and collaboration makes us the trusted partner for businesses across sectors.
      </Description>
      <Team>
        <Member>
          <MemberImage src={me} alt="Member 1" />
          <MemberName>Ait daoud El Houssein </MemberName>
          <MemberRole>Lead Developer</MemberRole>
        </Member>
        <Member>
          <MemberImage src={me2} alt="Member 2" />
          <MemberName>Aboughazaouat Ghassane</MemberName>
          <MemberRole>Software Engineer</MemberRole>
        </Member>
      </Team>
    </Container>
  );
};

export default AboutUs;
