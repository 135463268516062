import styled from 'styled-components';
import ServicePackage from '../../components/Package';
import { GAME_SERVICE_TITLE, SERVICE_DETAIL } from '../../api/Services';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 48px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  width: 90%;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const ServicePackageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  width: 50%;

  color: #fff;
  background: transparent;
  text-align: center;
  margin: 30px auto;
  z-index: 1;
  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.2rem;
    width: 90%;
  }
`;

function ServicePackages(props: any) {
  const { service } = props;

  const imgs = [
    'https://i.postimg.cc/2jcfMcf4/hot-air-balloon.png',
    'https://i.postimg.cc/DzrTN72Z/airplane.png',
    'https://i.postimg.cc/wvFd6FRY/startup.png',
  ];

  return (
    <Container>
      <Title>{service} Service Packages</Title>
      <Description>{GAME_SERVICE_TITLE[service]}</Description>
      <ServicePackageContainer>
        {Object.keys(SERVICE_DETAIL[service]).map((type, index) => (
          <ServicePackage
            key={service}
            title={type}
            price={SERVICE_DETAIL[service][type].price}
            features={SERVICE_DETAIL[service][type].feature}
            img={imgs[index]}
          />
        ))}
      </ServicePackageContainer>
    </Container>
  );
}

export default ServicePackages;
