export const BOTS = [
  {
    id: 4,
    type: 'bot',
    date: 'Mar 5, 2023',
    title: 'MicroPets 2D Mint Bot',
    description:
      'MicroPets 2D is a Telegram bot that allows you to track new mint MicroPets 2D NFTs',
    link: ' https://t.me/micropet_bot',
    tags: ['nft', 'tracking'],
    img: 'botImgs/micropet_bot.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/MicroPets2DMintBot',
  },

  {
    id: 6,
    type: 'bot',
    date: 'Aug 7, 2023',
    title: 'Hola Services Bot',
    description:
      'Hola Services is a Telegram bot that allows you to promote your services and find new clients',
    link: 'https://t.me/HolaServicesBot',
    tags: ['services', 'promotion'],
    img: 'botImgs/HolaServicesBot.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/HolaServicesBot',
  },
  {
    id: 7,
    type: 'bot',
    date: 'Aug 4, 2023',
    title: 'CompiBotErc',
    description: 'CompiBotErc is a Telegram bot that sends compliments to users',
    link: 'https://t.me/CompiBotErcBot',
    tags: ['compliments', 'group'],
    img: 'botImgs/CompiBotErcBot.webp',
    status: 'obline',
    rating: 4,
    github: 'https://github.com/Ghassanee/CompiBotErc',
  },
  {
    id: 8,
    type: 'bot',
    date: 'Aug 15, 2023',
    title: 'WBTC20BUYBOT',
    description: 'WBTC20BUYBOT is a Telegram bot to track new buys of WBTC20 token',
    link: 'https://t.me/WBTC20BUYBOT',
    tags: ['token', 'tracking'],
    img: 'botImgs/WBTC20BUYBOT.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/WBTC20BUYBOT',
  },
  {
    id: 9,
    type: 'bot',
    date: 'Aug 11, 2023',
    title: 'Victoria',
    description:
      'Victoria is a Telegram bot to translate messages from any language to any language',
    link: 'https://t.me/VictoriaAIBot',
    tags: ['translate', 'group'],
    img: 'botImgs/VictoriaAIBot.webp',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/VictoriaAIBot',
  },
  {
    id: 10,
    type: 'bot',
    date: 'Aug 10, 2023',
    title: 'TRND BOT PRO',
    description: 'TRND BOT PRO is a bot to promote your services and find new clients',
    link: 'https://t.me/TRND_probot',
    tags: ['services', 'promotion'],
    img: 'botImgs/TRND_probot.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/TRNDBOTPRO',
  },
  {
    id: 11,
    type: 'bot',
    date: 'Aug 10, 2023',
    title: 'TRND Latest Lock',
    description: 'TRND Latest Lock is a bot to track new locks in the ethereum network',
    link: 'https://t.me/trndlatestlock_bot',
    tags: ['ethereum', 'tracking'],
    img: 'botImgs/trndlatestlock_bot.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/TRNDLatestLock',
  },
  {
    id: 12,
    type: 'bot',
    date: 'Aug 29, 2023',
    title: 'DEXLIST_bot',
    description: 'DEXLIST_bot is a bot to track new listings on decentralized exchanges',
    link: 'https://t.me/DEXLIST_bot',
    tags: ['dex', 'tracking'],
    img: 'botImgs/DEXLIST_bot.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/DEXLIST_bot',
  },
  {
    id: 13,
    type: 'bot',
    date: 'Sep 6, 2023',
    title: 'PREDBOT',
    description: 'PREDBOT is a bot to predict the price of any token in the ethereum network',
    link: 'https://t.me/officialPRED_bot',
    tags: ['ethereum', 'prediction'],
    img: 'botImgs/officialPRED_bot.webp',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/PREDBOT',
  },
  {
    id: 14,
    type: 'bot',
    date: 'Sep 6, 2023',
    title: 'VIPNameService',
    description: 'VIPNameService is a bot to track new NFTs in the ethereum network',
    link: 'https://t.me/VIPnsBot',
    tags: ['nft', 'tracking'],
    img: 'botImgs/VIPnsBot.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/VIPNameService',
  },
  {
    id: 15,
    type: 'bot',
    date: 'Apr 24, 2023',
    title: 'BAYCardBot',
    description: 'BAYCardBot is a bot to track new NFTs in the ethereum network',
    link: 'https://t.me/BAYCardBot',
    tags: ['nft', 'tracking'],
    img: 'botImgs/BAYCardBot.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/BAYCardsBot',
  },
  {
    id: 16,
    type: 'bot',
    date: 'Sep 24, 2023',
    title: 'LpLockedBot',
    description: 'LpLockedBot is a bot to track new Locked LPs in the ethereum network',
    link: 'https://t.me/LP_Lock_Bot',
    tags: ['ethereum', 'tracking'],
    img: 'botImgs/LpLockedBot.jpg',
    status: 'offline',
    rating: 4,
    github: 'https://github.com/Ghassanee/LpLockedBot',
  },
  {
    id: 17,
    type: 'bot',
    date: 'Nov 29, 2023',
    title: 'TelegramHidanzBot',
    description: 'TelegramHidanzBot is a bot for our website',
    link: 'https://t.me/HidanzDevBot',
    tags: ['website', 'info'],
    img: 'botImgs/HidanzDevBot.jpg',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/TelegramHidanzBot',
  },

  {
    id: 21,
    type: 'bot',
    date: 'Dec 6, 2023',
    title: 'OfficialJoyBuyBot',
    description: 'OfficialJoyBuyBot is a solana buy bot that tracks new buys',
    link: 'https://t.me/OfficialJoyBuyBot',
    tags: ['solana', 'tracking'],
    img: 'botImgs/BuyBotSolana.jpg',
    status: 'offline',
    rating: 6,
    github: 'https://github.com/Ghassanee/BuyBotSolana',
  },
  {
    id: 22,
    type: 'bot',
    date: 'Dec 7, 2023',
    title: 'TwitterPostAlertBot',
    description: 'TwitterPostAlertBot is a telegram bot that tracks new tweets',
    link: 'https://t.me/JoyXAlertBot',
    tags: ['twitter', 'tracking'],
    img: 'botImgs/TwitterPostAlertBot.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/TwitterPostAlertBot',
  },

  {
    id: 25,
    type: 'bot',
    date: 'jan 11, 2024',
    title: 'SolanaNewListedBot',
    description: 'SolanaNewListedBot is a telegram bot that tracks new listings on solana',
    link: 'https://t.me/solanacontracts',
    tags: ['solana', 'listing'],
    img: 'botImgs/SolanaNewListedBot.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/SolanaNewListedBot',
  },
  {
    id: 26,
    type: 'bot',
    date: 'jan 12, 2024',
    title: 'solanaburned',
    description: 'SolanaBurnedLpBot is a telegram bot that tracks burned LPs on solana',
    link: 'https://t.me/solanaburned',
    tags: ['solana', 'burned'],
    img: 'botImgs/SolanaBurnedLpBot.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/SolanaBurnedLpBot',
  },
  {
    id: 27,
    type: 'bot',
    date: 'jan 14, 2024',
    title: 'NexyEthBurnLockDetector',
    description: 'Nexyfi Lock Bot is a telegram bot that tracks new locks and burns on ethereum',
    link: 'https://t.me/NexyEthBurnLockDetector',
    tags: ['ethereum', 'lock', 'burn'],
    img: 'botImgs/NexyEthBurnLockDetector.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/LockBotEther',
  },
  {
    id: 28,
    type: 'bot',
    date: 'jan 16, 2024',
    title: 'NexyEthNewGem',
    description: 'Nexyfi New Gem is a telegram bot that tracks new gems on ethereum',
    link: 'https://t.me/NexyEthNewGem',
    tags: ['ethereum', 'gem'],
    img: 'botImgs/NexyEthNewGem.jpg',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/LockBotEther',
  },
  {
    id: 29,
    type: 'bot',
    date: 'jan 16, 2024',
    title: 'Nexyethrenouncedetector',
    description:
      'Nexyfi Renouncement Detector is a telegram bot that tracks renounced tokens on ethereum',
    link: 'https://t.me/Nexyethrenouncedetector',
    tags: ['ethereum', 'renounced'],
    img: 'botImgs/Nexyethrenouncedetector.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/LockBotEther',
  },
  {
    id: 30,
    type: 'bot',
    date: 'jan 15, 2024',
    title: 'NexyfiEthAnalyzer_Bot',
    description: 'NEXYFI ETH ANALYZER ANALYSES ERC20 CONTRACTS FOR SECURITY AND SAFETY.',
    link: 'https://t.me/NexyfiEthAnalyzer_Bot',
    tags: ['ethereum', 'security'],
    img: 'botImgs/NexyfiEthAnalyzer_Bot.jpg',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/LockBotEther',
  },
  {
    id: 31,
    type: 'bot',
    date: 'jan 10, 2024',
    title: 'NexyEarlySnipesDetector',
    description: 'NEXYFI EARLY SNIPES DETECTOR GET ALERTS ON NEWLY, HARD SNIPED TOKENS.',
    link: 'https://t.me/NexyEarlySnipesDetector',
    tags: ['ethereum', 'snipes'],
    img: 'botImgs/NexyEarlySnipesDetector.jpg',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/LockBotEther',
  },
  {
    id: 32,
    type: 'bot',
    date: 'jan 10, 2024',
    title: 'NexyEthPrimeSignal',
    description: 'NEXYFI ETH PRIME SIGNAL SPOTS TOKENS WITH HIGH BUY PRESSURE .',
    link: 'https://t.me/NexyEthPrimeSignal',
    tags: ['ethereum', 'signal'],
    img: 'botImgs/NexyEarlySnipesDetector.jpg',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/LockBotEther',
  },
  {
    id: 33,
    type: 'bot',
    date: 'jan 19, 2024',
    title: 'NexySolNewGem',
    description: 'NEXYFI SOL NEW GEM IDENTIFIES ALL THE NEW SOLANA TOKEN PAIRS.',
    link: 'https://t.me/NexySolNewGem',
    tags: ['solana', 'gem'],
    img: 'botImgs/NexySolNewGem.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/SolanaBurnedAndNewPairs',
  },
  {
    id: 34,
    type: 'bot',
    date: 'jan 19, 2024',
    title: 'NexySolBurnDetector',
    description: 'NEXYFI SOL BURN DETECTOR FOLLOWS EVERY TOKEN BURNS IN SOLANA.',
    link: 'https://t.me/NexySolBurnDetector',
    tags: ['solana', 'burn'],
    img: 'botImgs/NexySolNewGem.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/SolanaBurnedAndNewPairs',
  },
  {
    id: 35,
    type: 'bot',
    date: 'jan 19, 2024',
    title: 'NexyfiSolAnalyzer_Bot',
    description: 'NEXYFI SOL ANALYZER ANALYSES SOLANA CONTRACTS FOR SECURITY AND SAFETY.',
    link: 'https://t.me/NexyfiSolAnalyzer_Bot',
    tags: ['solana', 'burn'],
    img: 'botImgs/NexySolNewGem.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/SolanaBurnedAndNewPairs',
  },
  {
    id: 36,
    type: 'bot',
    date: 'jan 19, 2024',
    title: 'NexySolPrime',
    description: 'NEXYFI SOL PRIME FINDS STABLE AND RELIABLE SOLANA TOKENS IN EARLY STAGE.',
    link: 'https://t.me/NexySolPrime',
    tags: ['solana', 'prime'],
    img: 'botImgs/NexySolPrime.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/SolanaBurnedAndNewPairs',
  },
  {
    id: 37,
    type: 'bot',
    date: 'jan 25, 2024',
    title: 'MetisTokens',
    description: 'First Token Contracts Scanner on Metis',
    link: 'https://t.me/MetisTokens',
    tags: ['metis', 'contracts'],
    img: 'botImgs/MetisTokens.jpg',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/dexfusionLockBot',
  },
  {
    id: 38,
    type: 'bot',
    date: 'jan 25, 2024',
    title: 'avalanchecontracts',
    description: 'Get the latest Avalanche Contracts and Tokens here.',
    link: 'https://t.me/avalanchecontracts',
    tags: ['avalanche', 'contracts'],
    img: 'botImgs/avalanchecontracts.jpeg',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/dexfusionLockBot',
  },
  {
    id: 39,
    type: 'bot',
    date: 'jan 28, 2024',
    title: 'arbitrumcontracts',
    description: ' Get the latest Arbitrum Contracts and Tokens here.',
    link: 'https://t.me/arbitrumcontracts',
    tags: ['arbitrum', 'contracts'],
    img: 'botImgs/arbitrumcontracts.jpeg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/dexfusionLockBot',
  },
  {
    id: 40,
    type: 'bot',
    date: 'jan 28, 2024',
    title: 'bsccontracts',
    description: 'Get the latest Binance Contracts and Tokens here.',
    link: 'https://t.me/bsccontracts',
    tags: ['bsc', 'contracts'],
    img: 'botImgs/bsccontracts.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/dexfusionLockBot',
  },
  {
    id: 41,
    type: 'bot',
    date: 'jan 25, 2024',
    title: 'Hidanz AI Bot',
    description: 'Generate images, chat with multiple personas and more',
    link: 'https://t.me/Hidanz_ai_bot',
    tags: ['AI', 'Image generation'],
    img: 'botImgs/hidanzai.jpg',
    status: 'online',
    rating: 6,
    github: '',
  },

  {
    id: 42,
    type: 'bot',
    date: 'jan 5, 2024',
    title: 'BuyTech clone',
    description:
      'a clone of the famous buytech bot, get the latest buys of any token (BSC and ETH)',
    link: '',
    tags: ['BSC', 'ETH'],
    img: 'botImgs/buybot.jpg',
    status: 'offline',
    rating: 6,
    github: '',
  },

  {
    id: 44,
    type: 'bot',
    date: 'Feb 22, 2024',
    title: 'MemeBBCbot',
    description: 'MemeBBCbot is a bot to generate memes',
    link: 'https://t.me/MemeBBCbot',
    tags: ['meme', 'generator'],
    img: 'botImgs/MemeBBCbot.jpg',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/MemeBBCbot',
  },
  {
    id: 45,
    type: 'bot',
    date: 'Feb 10, 2024',
    title: 'Solana Contract Analyzer Bot',
    description:
      'Solana Contract Analyzer Bot is a telegram bot that tracks new contracts on solana',
    link: 'https://t.me/solanacontractanalyzerbot',
    tags: ['solana', 'analyzer'],
    img: 'botImgs/solanacontractanalyzerbot.jpg',
    status: 'online',
    rating: 5,
    github: '',
  },
  {
    id: 46,
    type: 'bot',
    date: 'Feb 10, 2024',
    title: 'FLOCO TREND BOT',
    description: 'FLOCO TREND BOT is a bot to promote your services and find new clients',
    link: 'https://t.me/FLOCOTREND_bot',
    tags: ['promotion'],
    img: 'botImgs/FLOCOTREND_bot.jpg',
    status: 'online',
    rating: 4,
    github: '',
  },
  {
    id: 47,
    type: 'bot',
    date: 'May 30, 2024',
    title: 'Solama Task Bot',
    description: 'Solama Task Bot is a bot to track new tasks on twitter with rewards',
    link: 'https://t.me/SolamaTaskBot',
    tags: ['tasks', 'twitter'],
    img: 'botImgs/SolamaTaskBot.jpg',
    status: 'online',
    rating: 6,
    github: '',
  },
  {
    id: 48,
    type: 'bot',
    date: 'Jul 30, 2024',
    title: 'Twitter Raid Bot',
    description: 'Twitter Raid Bot is a bot to track new raids on twitter',
    link: 'https://t.me/TwitterXRaidBot',
    tags: ['raids', 'twitter'],
    img: 'botImgs/TwitterRaidBot.jpg',
    status: 'online',
    rating: 6,
    github: '',
  },
].sort((a, b) => b.rating - a.rating);
// .filter((bot) => bot.status === 'online');
