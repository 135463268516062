import React from 'react';
import { styled } from 'styled-components';
import Button from '../../components/Button';
import Lottie from 'lottie-react';
import robotAnimation from '../../assets/animations/Home.json';
import { TELEGRAM } from '../../api/SocialLinks';
const Container = styled.div`
  padding: 20px;
  text-align: center;

  //smaller screens
  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;
const ContainerInner = styled.div`
  text-align: left;
  max-width: 1300px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
  height: 800px;

  //smaller screens
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
`;

const Title = styled.h1`
  font-size: 4.2rem;
  margin-bottom: 10px;
  color: #fff;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #fff;
  padding-bottom: 20px;
  font-weight: 500;
  width: 90%;
  line-height: 1.5;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 1rem;
    width: 100%;
  }
`;
const AnimationSection = styled.div`
  position: relative;
  width: 500px;
  margin: 0 auto;

  //smaller screens
  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    order: -1;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  gap: 50px;

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    gap: 10px;
    flex-direction: column;
  }
`;
export default function HeroSection() {
  return (
    <Container>
      <ContainerInner>
        <div>
          <Title>🛠️ Crafting Digital Excellence with Hidanz Solutions!</Title>
          <Description>
            From Tailored Bots to Full-Stack Development: Unlock Seamless Experiences, Drive Digital
            Innovation, and Partner with the Hidanz Tech Community for Success.
          </Description>
          <Buttons>
            <Button size="large" type="primary" onClick={() => window.open(TELEGRAM(), '_blank')}>
              Get Started
            </Button>
            <Button size="large" type="secondary" onClick={() => window.open('./bots/work')}>
              Explore Projects
            </Button>
          </Buttons>
        </div>
        <AnimationSection>
          <Lottie animationData={robotAnimation} loop={true} />
        </AnimationSection>
      </ContainerInner>
    </Container>
  );
}
