import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';

const ServiceCardContainer = styled.div<any>`
  height: 250px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px rgba(0, 0, 0);
  background-size: 120%;
  transition: background-size 0.3s;

  &:hover {
    box-shadow: inset 0 0 100px rgba(0, 0, 0);
    background-size: 130%;
  }
`;

const ServiceCardName = styled.h3`
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.5);
  text-stroke: 2px #000;
`;

export default function ServiceCard(props: any) {
  const { image, title, link } = props;

  const click = () => {
    ReactGA.event({
      category: 'Service',
      action: 'Click',
      label: `Clicked on ${title} in home page `,
    });

    window.open(link, '_self');
  };
  return (
    <ServiceCardContainer onClick={click} src={require(`../../assets/ServiceBg/${image}`)}>
      <ServiceCardName>{title}</ServiceCardName>
    </ServiceCardContainer>
  );
}
