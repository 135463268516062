import styled from 'styled-components';
import Button from '../../components/Button';
import { SERVICE } from '../../api/Services';
import { TELEGRAM } from '../../api/SocialLinks';

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 100vh;
  overflow: hidden;
  position: relative; // <-- To contain absolutely positioned slides
  // fade at the bottom
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%);
    width: 100%;
    height: 10em;
  }
`;

const SlideContainer = styled.div<{ isvisible: any }>`
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
  opacity: ${({ isvisible }) => (isvisible ? 1 : 0)};
  display: flex;
  transition: opacity 0.5s ease; // <-- Opacity transition for fade effect
  align-items: center;
  justify-content: center;
`;

const Slide = styled.img`
  width: 100vw;
  background: transparent;
  height: 100vh;
  object-fit: cover; /* This ensures the image covers the container while maintaining aspect ratio */
  object-position: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  background: transparent;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 25px;
  z-index: 1;
  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const TitleContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0 0 100px 100px rgba(0, 0, 0, 0.7);
  width: 50%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const Description = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  background: transparent;
  text-align: center;
  margin: 0;
  margin-right: auto;
  margin-bottom: 25px;
  z-index: 1;
  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const HeroSection = (props: any) => {
  const { service } = props;

  if (!SERVICE[service]) {
    window.location.href = '/';
  }

  return (
    <SliderContainer>
      <SlideContainer isvisible={+true}>
        <TitleContainer>
          <Title> {SERVICE[service].caption} </Title>
          <Description> {SERVICE[service].description} </Description>
          <ButtonContainer>
            <Button onClick={() => window.open(TELEGRAM(), '_blank')}>Get Started</Button>
            <Button onClick={() => window.open(TELEGRAM(), '_blank')}>Learn More</Button>
          </ButtonContainer>
        </TitleContainer>
        <Slide
          src={require(`../../assets/ServiceBg/${SERVICE[service].src}`)}
          alt={SERVICE[service].caption}
          loading="lazy"
        />
      </SlideContainer>
    </SliderContainer>
  );
};

export default HeroSection;
