export const REF: any = {
  l: 'Linkedin',
  g: 'Github',
  t: 'Twitter',
  i: 'Instagram',
  fa: 'Facebook',
  s: 'Stackoverflow',
  f: 'Fiverr',
  up: 'Upwork',
};
