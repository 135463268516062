export const GAMES = [
  {
    id: 31,
    type: 'game',
    date: 'Sep 9, 2023',
    title: 'Dungeon Delve game',
    description: 'Retro-style pixel adventure game ',
    link: 'https://venerable-lolly-28f914.netlify.app/',
    tags: ['game', 'dungeon', 'adventure'],
    img: 'GameImgs/dungeon-delve.png',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 32,
    type: 'game',
    date: 'Apr 16, 2023',
    title: 'Starcatcher',
    description: 'Relaxing Puzzle game',
    link: 'https://starcatchergame.netlify.app',
    tags: ['game', 'puzzle'],
    img: 'GameImgs/starcatcher.png',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 33,
    type: 'game',
    date: 'Jun 1, 2023',
    title: 'Cloudy package',
    description: 'Collect yellow and green packages',
    link: 'https://cloudy-package.netlify.app',
    tags: ['game', 'addictive', 'infinity'],
    img: 'GameImgs/cloudy-package.png',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 34,
    type: 'game',
    date: 'Mar 11, 2022',
    title: 'Color Control',
    description: 'Make the other ships destroy each other to win!',
    link: 'https://color-attack.netlify.app/',
    tags: ['game', 'fun', 'smart'],
    img: 'GameImgs/color-control.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 35,
    type: 'game',
    date: 'Dec 20, 2022',
    title: 'Word Asteroid destroyer',
    description: 'Type the word the asteroid has to destroy',
    link: 'https://spaceword.netlify.app/',
    tags: ['game', 'word', 'asteroid'],
    img: 'GameImgs/word-asteroid-destroyer.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 36,
    type: 'game',
    date: 'Jun 23, 2022',
    title: 'Disk Room',
    description: 'Simple game where you have to dodge the saw and survive',
    link: 'https://disk-room.netlify.app',
    tags: ['game', 'dodge', 'saw'],
    img: 'GameImgs/disk-room.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 37,
    type: 'game',
    date: 'Jun 10, 2023',
    title: 'Vegetable orbit',
    description: 'Score as much as you can by collecting vegetables',
    link: 'https://vegetable-orbit.netlify.app',
    tags: ['game', 'vegetable', 'orbit'],
    img: 'GameImgs/vegetable-orbit.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 37,
    type: 'game',
    date: 'July 10, 2022',
    title: 'Gravity cat',
    description: 'control a cat head and dodge obstacle from right to left',
    link: 'https://gregarious-dodol-02bf34.netlify.app/',
    tags: ['game', 'cat', 'dodge'],
    img: 'GameImgs/gravity-cat.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 1,
    type: 'game',
    date: 'Oct 10, 2023',
    title: 'Blob Pacman',
    description: 'Blob Pacman is a game like Pacman.',
    link: 'https://blobpacman.netlify.app/pacman.html',
    tags: ['game', 'singleplayer'],
    img: 'GameImgs/blobpacman.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/blobpacman',
  },
  {
    id: 2,
    type: 'game',
    date: 'Apr 22, 2022',
    title: 'Grumpy Cat Coin',
    description: 'Grumpy Cat Coin is a space shooter game.',
    link: 'https://grumpycatcoin.netlify.app/export/grumpycatcoin',
    tags: ['game', 'space', 'shooter'],
    img: 'GameImgs/grumpycatcoin.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/grumpycatcoin',
  },
  {
    id: 3,
    type: 'game',
    date: 'Mar 29, 2023',
    title: 'Runner Game App',
    description: 'Infinity runner game.',
    link: 'https://runner-game-app.netlify.app/runner',
    tags: ['game', 'infinity', 'runner'],
    img: 'GameImgs/runner-game-app.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/runner-game-app',
  },
  {
    id: 4,
    type: 'game',
    date: 'Jul 17, 2023',
    title: 'scarabdash',
    description: 'Game like Chrome Dino.',
    link: 'https://scarabdash.netlify.app/',
    tags: ['game', 'chrome', 'dino'],
    img: 'GameImgs/scarabdash.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/scarabdash',
  },
  {
    id: 5,
    type: 'game',
    date: 'Jun 6, 2023',
    title: 'pimple-popping',
    description: 'Fun game to pop pimples.',
    link: 'https://pimple-popping.netlify.app/',
    tags: ['game', 'pimple', 'popping'],
    img: 'GameImgs/pimple-popping.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/pimple-popping',
  },
  {
    id: 6,
    type: 'game',
    date: 'May 25, 2023',
    title: 'Jumper Ninja',
    description: 'Platformer game.',
    link: 'https://jumper-ninja.netlify.app/',
    tags: ['game', 'platformer'],
    img: 'GameImgs/jumper-ninja.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/jumper-ninja',
  },
  {
    id: 7,
    type: 'game',
    date: 'Dec 3, 2021',
    title: 'Rock Paper Pimple',
    description: 'Rock Paper Scissors game.',
    link: 'https://rock-paper-pimple.netlify.app/',
    tags: ['game', 'rock', 'paper', 'scissors'],
    img: 'GameImgs/rock-paper-pimple.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/rock-paper-pimple',
  },
  {
    id: 8,
    type: 'game',
    date: 'Nov 8, 2021',
    title: 'Kouga Bacon Quest',
    description: 'Snake game but it is a dog.',
    link: 'https://kouga-bacon-quest.netlify.app/',
    tags: ['game', 'snake'],
    img: 'GameImgs/kouga-bacon-quest.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/kouga-bacon-quest',
  },
  {
    id: 9,
    type: 'game',
    date: 'Apr 29, 2022',
    title: 'Hape Beasts',
    description: 'Infinity runner game.',
    link: 'https://hape-beasts.netlify.app/',
    tags: ['game', 'infinity', 'runner'],
    img: 'GameImgs/hape-beasts.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/hape-beasts',
  },
  {
    id: 10,
    type: 'game',
    date: 'Aug 16, 2022',
    title: 'Runner Dog',
    description: 'Infinity runner game with a dog.',
    link: 'https://runner-dog.netlify.app/',
    tags: ['game', 'infinity', 'runner'],
    img: 'GameImgs/runner-dog.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/runner-dog',
  },
  {
    id: 11,
    type: 'game',
    date: 'Dec 5, 2022',
    title: 'Date Night Gameplay',
    description: 'Similar to the game "Would You Rather".',
    link: 'https://date-night-gameplay.netlify.app/',
    tags: ['game', 'fun', 'friends'],
    img: 'GameImgs/date-night-gameplay.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/date-night-gameplay',
  },
  {
    id: 12,
    type: 'game',
    date: 'Dec 24, 2022',
    title: 'Spades Online',
    description: 'Play spades online with friends.',
    link: 'https://spades-online.netlify.app/',
    tags: ['game', 'spades', 'friends'],
    img: 'GameImgs/spades-online.webp',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/proofofx',
  },
  {
    id: 13,
    type: 'game',
    date: 'Apr 22, 2022',
    title: 'Mutant Shiba Run',
    description: 'Dino game but with a shiba.',
    link: 'https://mutant-shiba-run.netlify.app/',
    tags: ['game', 'shiba', 'dino'],
    img: 'GameImgs/mutant-shiba-run.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/mutant-shiba-run',
  },
  {
    id: 14,
    type: 'game',
    date: 'Mar 29, 2023',
    title: 'Nearbiezrun',
    description: 'Dino game but with a Nearbiez.',
    link: 'https://nearbiezrun.netlify.app/',
    tags: ['game', 'nearbiez', 'dino'],
    img: 'GameImgs/nearbiezrun.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/nearbiezrun',
  },
  {
    id: 15,
    type: 'game',
    date: 'Jul 17, 2023',
    title: 'The Akiras',
    description: 'Infinity runner game with a ninja.',
    link: 'https://theakiras.netlify.app/',
    tags: ['game', 'infinity', 'runner'],
    img: 'GameImgs/theakiras.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/theakiras',
  },
  {
    id: 16,
    type: 'game',
    date: 'Jun 6, 2023',
    title: 'Imaginary Ones Run',
    description: 'Dinerent infinity runner game.',
    link: 'https://imaginaryonesrun.netlify.app/',
    tags: ['game', 'infinity', 'runner'],
    img: 'GameImgs/imaginaryonesrun.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/imaginaryonesrun',
  },
  {
    id: 17,
    type: 'game',
    date: 'May 25, 2023',
    title: 'Trippin Ape tribe Run',
    description: 'Dino game but with a shiba.',
    link: 'https://trippinapetriberun.netlify.app/',
    tags: ['dino', 'game'],
    img: 'GameImgs/trippinapetriberun.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/trippinapetriberun',
  },
  {
    id: 18,
    type: 'game',
    date: 'Dec 3, 2021',
    title: 'Hex Tris',
    description: 'Hex Tris is a game like Tetris.',
    link: 'https://hex-tris.netlify.app/',
    tags: ['game', 'tetris'],
    img: 'GameImgs/hex-tris.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/hex-tris',
  },
  {
    id: 19,
    type: 'game',
    date: 'Nov 8, 2021',
    title: 'Basket Thrower',
    description: 'Throw the ball into the basket.',
    link: 'https://basket-thrower.netlify.app/',
    tags: ['game', 'basket', 'thrower'],
    img: 'GameImgs/basket-thrower.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/basket-thrower',
  },
  {
    id: 20,
    type: 'game',
    date: 'Apr 29, 2023',
    title: 'g2048',
    description: 'Game like 2048.',
    link: 'https://g2048.netlify.app/',
    tags: ['game', '2048'],
    img: 'GameImgs/g2048.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/g2048',
  },
  {
    id: 21,
    type: 'game',
    date: 'Aug 16, 2022',
    title: 'Evil Elon',
    description: 'Space shooter game.',
    link: 'https://evil-nurse.netlify.app/',
    tags: ['game', 'space', 'shooter'],
    img: 'GameImgs/evil-elon.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/evil-elon',
  },
  {
    id: 22,
    type: 'game',
    date: 'Dec 5, 2022',
    title: 'Room Escape',
    description: 'Puzzle game.',
    link: 'https://room-escape.netlify.app/',
    tags: ['game', 'puzzle'],
    img: 'GameImgs/room-escape.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/room-escape',
  },
  {
    id: 23,
    type: 'game',
    date: 'Dec 30, 2021',
    title: 'Gold Miner',
    description: 'Use the crane to get gold.',
    link: 'https://gold-miner.netlify.app/',
    tags: ['game', 'gold', 'miner'],
    img: 'GameImgs/gold-miner.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/gold-miner',
  },
  {
    id: 24,
    type: 'game',
    date: 'Jun 29, 2022',
    title: 'Candy Land Game',
    description: 'Game like Candy Crush.',
    link: 'https://candy-land-game.netlify.app/',
    tags: ['game', 'candy', 'crush'],
    img: 'GameImgs/candy-land-game.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/candy-land-game',
  },
  {
    id: 25,
    type: 'game',
    date: 'Mar 17, 2023',
    title: 'Lamberjack',
    description: 'Cut the tree and avoid the branches.',
    link: 'https://lamberjack.netlify.app/',
    tags: ['game', 'lamberjack'],
    img: 'GameImgs/lamberjack.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/lamberjack',
  },
  {
    id: 26,
    type: 'game',
    date: 'Aug 1, 2023',
    title: 'Boxing Arcade',
    description: 'Punch the bag as fast as you can.',
    link: 'https://boxing-arcade.netlify.app/',
    tags: ['game', 'boxing', 'arcade'],
    img: 'GameImgs/boxing-arcade.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/boxing-arcade',
  },
  {
    id: 27,
    type: 'game',
    date: 'Feb 5, 2023',
    title: 'Egg Basket',
    description: 'Catch the eggs in the basket.',
    link: 'https://egg-basket.netlify.app/',
    tags: ['game', 'egg', 'basket'],
    img: 'GameImgs/egg-basket.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/egg-basket',
  },
  {
    id: 28,
    type: 'game',
    date: 'Jan 24, 2021',
    title: 'Try Color Game',
    description: 'Puzzle game.',
    link: 'https://try-color-game.netlify.app/',
    tags: ['game', 'puzzle'],
    img: 'GameImgs/try-color-game.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/try-color-game',
  },
  {
    id: 29,
    type: 'game',
    date: 'Oct 16, 2022',
    title: 'Red Nose Inu Game',
    description: 'Flappy bird game.',
    link: 'https://red-nose-inu-game.netlify.app/',
    tags: ['game', 'flappy', 'bird'],
    img: 'GameImgs/red-nose-inu-game.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/red-nose-inu-game',
  },
  {
    id: 30,
    type: 'game',
    date: 'Sep 9, 2022',
    title: 'Balloon Crush',
    description: 'Crush the balloons.',
    link: 'https://balloon-crush.netlify.app/',
    tags: ['game', 'balloon', 'crush'],
    img: 'GameImgs/balloon-crush.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/balloon-crush',
  },
  {
    id: 31,
    type: 'game',
    date: 'Nov 28, 2023',
    title: 'ChildrenExercises',
    description: 'Game for children to learn',
    link: 'https://exercises-game.netlify.app/',
    tags: ['game', 'children', 'exercises'],
    img: 'GameImgs/ChildrenExercises.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/ChildrenExercises',
  },
  {
    id: 32,
    type: 'game',
    date: 'Dec 1, 2023',
    title: 'CarAnimation',
    description: 'Car parking simulation',
    link: 'https://car-animation-path.netlify.app/',
    tags: ['game', 'car', 'parking'],
    img: 'GameImgs/CarAnimation.png',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/CarAnimation',
  },
  {
    id: 33,
    type: 'game',
    date: 'Dec 2, 2023',
    title: 'Fruit Ninja',
    description: 'Fruit Ninja slicing game',
    link: 'https://fruit-ninja-game.netlify.app/',
    tags: ['game', 'fruit', 'ninja'],
    img: 'GameImgs/FruitNinja.png',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/FruitNinja',
  },
  {
    id: 34,
    type: 'game',
    date: 'Dec 2, 2023',
    title: 'PlxyerGame',
    description: 'PlxyerGame is a space game',
    link: 'https://plxyer.netlify.app/',
    tags: ['game', 'space'],
    img: 'GameImgs/PlxyerGame.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/PlxyerGane',
  },
  {
    id: 35,
    type: 'game',
    date: 'Apr 29, 2024',
    title: 'AsteroidDestroyer',
    description: 'AsteroidDestroyer is a space game where you have to destroy the asteroid',
    link: 'https://asteroid-destroyer-game.netlify.app/',
    tags: ['game', 'space', 'asteroid'],
    img: 'GameImgs/AsteroidDestroyer.png',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/AsteroidDestroyer ',
  },
].sort((a, b) => b.rating - a.rating);
// .filter((bot) => bot.status === 'online');
