import React from 'react';
import { SERVICE } from '../../api/Services';
import ServiceCard from '../../components/ServiceCard';
import styled from 'styled-components';

const ServiceSectionContainer = styled.div`
  padding: 20px;
  text-align: center;
  background-position: top;
  max-width: 1300px;
  margin: 0 auto;
  padding: 300px 0;
  //smaller screens
  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;
  }
`;

const ServiceSectionInner = styled.div`
  text-align: left;
`;

const ServiceSectionTitle = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0;
  margin-right: auto;
  margin-bottom: 25px;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ServiceSectionDescription = styled.p`
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  font-weight: 400;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
  margin-bottom: 100px;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.1rem;
    width: 100%;
  }
`;

const ServiceSectionChildren = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;

  //smaller screens
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default function ServiceSection() {
  return (
    <ServiceSectionContainer id="services">
      <ServiceSectionInner>
        <ServiceSectionTitle>What We Do</ServiceSectionTitle>
        <ServiceSectionDescription>
          We make websites, games, bots, and telegram games. We are a team of developers who are
          passionate about what we do.
        </ServiceSectionDescription>
        <ServiceSectionChildren>
          {Object.keys(SERVICE).map((service) => (
            <ServiceCard
              key={service}
              image={SERVICE[service].src}
              title={SERVICE[service].title}
              description={SERVICE[service].description}
              link={
                service === 'bot'
                  ? '/service?service=bot'
                  : service === 'game'
                  ? '/service?service=game'
                  : service === 'website'
                  ? '/service?service=website'
                  : '/service?service=telegram_game'
              }
            />
          ))}
        </ServiceSectionChildren>
      </ServiceSectionInner>
    </ServiceSectionContainer>
  );
}
