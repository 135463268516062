import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { TELEGRAM } from '../../api/SocialLinks';
import ReactGA from 'react-ga4';

const PackageContainer = styled.div`
  min-width: 100%;
  height: 100%;
  width: 320px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 0 20px 0;
  * {
    background-color: #fff;
  }
  &:hover {
    transform: translateY(-5px);
  }
`;

const PackageHeader = styled.div`
  font-size: 24px;
  font-weight: 300;
  text-transform: capitalize;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: #1e88e5;
  border-radius: 15px 15px 0 0;
  color: white;
`;

const PackageIcon = styled.img`
  width: 120px;
  height: 120px;
  margin: 30px auto;
  display: block;
  padding: 10px;
`;

const PackagePrice = styled.div`
  color: #000;
  font-size: 48px;
  text-align: center;
  font-weight: 600;
`;

const PackageFeatures = styled.ul`
  list-style-type: none;
  padding: 10px;
  padding-bottom: 30px;
  te li {
    padding: 8px 0;
  }
`;

const PackageFeature = styled.li`
  color: #7d7c7c;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 400;
  &::before {
    content: '';
    background-image: url('https://i.postimg.cc/ht7g996V/check.png');
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
`;

const ServicePackage = ({ title, price, features, img }: any) => {
  const click = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: `Clicked on ${title} package button of ${title} page`,
    });
    console.log('clicked');

    window.open(TELEGRAM(), '_blank');
  };
  return (
    <PackageContainer>
      <PackageHeader>{title} Package</PackageHeader>
      <PackageIcon src={img} />
      <PackagePrice>${price}</PackagePrice>
      <PackageFeatures>
        {features.map((feature: any, index: any) => (
          <PackageFeature key={index}>{feature}</PackageFeature>
        ))}
      </PackageFeatures>
      <Button type="secondary" onClick={click}>
        Get Now
      </Button>
    </PackageContainer>
  );
};

export default ServicePackage;
