import React from 'react';
import styled from 'styled-components';
import { FIVER, INSTAGRAM, TELEGRAM_CHANNEL, TWITTER } from '../../api/SocialLinks';
import ReactGA from 'react-ga4';

const FooterWrapper = styled.footer`
  color: #000;
  padding: 20px;
  text-align: center;
`;

const SocialIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 300px;
`;

const Icon = styled.img`
  width: 35px;
  height: 35px;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  &:hover {
    transform: scale(1.1);
  }
`;
const Text = styled.p`
  font-size: 0.8rem;
  color: #fff;
  font-weight: 450;
  line-height: 1.5;
  text-align: center;
`;

const Footer: React.FC = () => {
  const click = (link: any) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: `Clicked on ${link} to contact me from footer`,
    });
    window.open(link, '_blank');
  };
  return (
    <FooterWrapper>
      <SocialIcon>
        <Icon
          src="https://img.icons8.com/?size=256&id=63306"
          alt="telegram"
          onClick={() => click(TELEGRAM_CHANNEL)}
        />

        <Icon
          src="https://img.icons8.com/color/twitter"
          alt="twitter"
          onClick={() => click(TWITTER)}
        />
        <Icon
          src="https://img.icons8.com/?size=256&id=32323"
          alt="instagram"
          onClick={() => click(INSTAGRAM)}
        />
        <Icon src="https://img.icons8.com/color/fiverr" alt="fiveer" onClick={() => click(FIVER)} />
      </SocialIcon>
      <Text>© 2024 All rights reserved. Made with ❤️ by Hidanz</Text>
    </FooterWrapper>
  );
};

export default Footer;
