// ImageSlider.tsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { TELEGRAM } from '../../api/SocialLinks';

// Define your image data (replace with your own images and captions)
const images = [
  {
    src: 'crypto.webp',
    caption: 'Promote Your Crypto Project',
    description:
      'A tailor-made service to boost your cryptocurrency venture, ensuring maximum reach and impact in the digital currency market.',
  },
  {
    src: 'games.webp',
    caption: 'Create Amazing Games',
    description:
      'Experience cutting-edge game development solutions that captivate and engage players around the world.',
  },
  {
    src: 'bot.webp',
    caption: 'We Build Bots',
    description:
      'Harness the power of AI and automation. Our bots are designed to streamline tasks and enhance user experiences.',
  },
  {
    src: 'website.webp',
    caption: 'Build Your Website',
    description:
      'Craft a web presence that stands out. From design to deployment, we cover every step to ensure a responsive and dynamic website for your brand.',
  },
  {
    src: 'all.webp',
    caption: 'We Do It All',
    description:
      'A holistic approach to digital solutions. No matter your need, our comprehensive services have got you covered.',
  },
  {
    src: 'community.webp',
    caption: 'We help you build your community',
    description:
      'Engage and grow your audience. We provide strategies and tools to foster connections and nurture a loyal community around your brand or cause.',
  },
  {
    src: 'experts.webp',
    caption: 'We are experts in what we do',
    description:
      'Rely on a team of seasoned professionals. Our expertise is backed by years of experience and a passion for innovation and excellence.',
  },
];

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 100vh;
  overflow: hidden;
  position: relative; // <-- To contain absolutely positioned slides
  // fade at the bottom
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%);
    width: 100%;
    height: 10em;
  }
`;

const SlideContainer = styled.div<{ isvisible: any }>`
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
  opacity: ${({ isvisible }) => (isvisible ? 1 : 0)};
  display: flex;
  transition: opacity 0.5s ease; // <-- Opacity transition for fade effect
  align-items: center;
  justify-content: center;
`;

const Slide = styled.img`
  width: 100vw;
  background: transparent;
  height: 100vh;
  object-fit: cover; /* This ensures the image covers the container while maintaining aspect ratio */
  object-position: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  background: transparent;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 25px;
  z-index: 1;
  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const TitleContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0 0 100px 100px rgba(0, 0, 0, 0.7);
  width: 50%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const Description = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  background: transparent;
  text-align: center;
  margin: 0;
  margin-right: auto;
  margin-bottom: 25px;
  z-index: 1;
  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ImageSlider: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }

    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SliderContainer>
      {images.map((image, index) => (
        <SlideContainer key={index} isvisible={+(index === currentSlide)}>
          <TitleContainer>
            <Title> {image.caption} </Title>
            <Description> {image.description} </Description>
            <ButtonContainer>
              <Button onClick={() => window.open(TELEGRAM(), '_blank')}>Get Started</Button>
              <Button onClick={() => window.open('#work', '_self')}>Learn More</Button>
            </ButtonContainer>
          </TitleContainer>
          <Slide
            src={require(`../../assets/homeBg/${isMobile ? `mobile/${image.src}` : image.src}`)}
            alt={image.caption}
            loading="lazy"
          />
        </SlideContainer>
      ))}
    </SliderContainer>
  );
};

export default ImageSlider;
