import React, { useState } from 'react';
import styled from 'styled-components';
import { FIVER, TELEGRAM, UPWORK } from '../../api/SocialLinks';
import ReactGA from 'react-ga4';

const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 60px;
  right: 60px;
  animation: scale 3s ease-in-out infinite;
  rotate: 90deg;
  z-index: 1000;
  background-color: transparent;
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  //smaller screens
  @media (max-width: 768px) {
    bottom: 30px;
    right: 30px;
  }
`;

const FloatingActionButtonWrapper = styled.div`
  width: 60px;
  height: 60px;
  color: white;
  font-size: 40px;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(45deg);
  //smaller screens
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const ShareButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  background-color: #29b6f6;
  box-shadow: 0 0 9px 1px #fff;
  z-index: 1000;
  border-radius: 15px;

  #share-icon {
    background-color: transparent;
    animation: rotate 0.5s ease-in-out;
  }
`;

const ExpandSection = styled.ul`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;

  li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
    color: black;
    list-style-type: none;
    transform: scale(0.85);
    transition: 0.5s;
    border-radius: 15px;

    img {
      width: 40px;
      height: 40px;
    }

    &:hover {
      background-color: #ffcdab;
    }
  }

  &.active {
    li:nth-child(1) {
      top: 100%;
      left: 100%;
      transition-delay: 0s;
    }
    li:nth-child(2) {
      top: 100%;
      left: 0%;
      transition-delay: 0.1s;
    }
    li:nth-child(3) {
      top: 100%;
      left: -100%;
      transition-delay: 0.2s;
    }
    li:nth-child(4) {
      top: 0%;
      left: -100%;
      transition-delay: 0.3s;
    }
    li:nth-child(5) {
      top: -100%;
      left: -100%;
      transition-delay: 0.4s;
    }
  }
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  rotate: -135deg;
  position: relative;
  object-fit: cover;
  background-color: transparent;
  border-radius: 50%;
  z-index: 1000;
  position: relative;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 0 3px #fff;
  }
`;

const Fab = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    ReactGA.event({
      category: 'Click',
      action: 'Clicked on contact FAB to see contact links',
    });

    setIsActive(!isActive);
  };

  const click = (link: string) => {
    ReactGA.event({
      category: 'Click',
      action: `Clicked on ${link} FAB to contact me`,
    });
    window.open(link, '_blank');
  };

  return (
    <SectionWrapper>
      <FloatingActionButtonWrapper>
        <ShareButton className={isActive ? 'active' : ''} onClick={toggleActive}>
          <Icon id="share-icon" src="https://img.icons8.com/?size=256&id=63306" alt="share" />
        </ShareButton>
        <ExpandSection className={isActive ? 'active' : ''}>
          <li
            style={{
              backgroundColor: '#29b6f6',
            }}
          >
            <Icon
              src="https://img.icons8.com/?size=256&id=63306"
              alt="telegram"
              onClick={() => click(TELEGRAM())}
            />
          </li>
          <li
            style={{
              backgroundColor: '#1bab08',
            }}
          >
            <Icon
              src="https://img.icons8.com/?size=256&id=2y_wcSzVjqiQ&color=ffffff"
              alt="upwork"
              onClick={() => click(UPWORK)}
            />
          </li>
          <li
            style={{
              backgroundColor: '#fff',
            }}
          >
            <Icon
              src="https://img.icons8.com/?size=256&id=14h574ySQ7kG&format=png&color=08b333"
              alt="fiverr"
              onClick={() => click(FIVER)}
            />
          </li>
        </ExpandSection>
      </FloatingActionButtonWrapper>
    </SectionWrapper>
  );
};

export default Fab;
