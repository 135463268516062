import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';

const CardBody = styled.article<{ background: string }>`
  width: 300px;
  min-width: 250px;
  border-radius: 10px;
  overflow: hidden; /* Ensure content stays within rounded corners */
  position: relative;
  margin: 0;
  scroll-snap-align: start;
  clear: both;
  min-height: 420px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.background}),
      linear-gradient(to right, #0f0f0f, #0f0f0f);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.5s ease-in-out; /* Smooth transition for zoom effect */
    z-index: 0; /* Place behind other content */
  }

  &:hover:before {
    transform: scale(1.1); /* Scale up the background */
  }

  &:hover {
    .card-bot {
      background: linear-gradient(to top, #0f0f0f 30%, transparent); /* Adjust as needed */
    }
  }

  // smaller screens
  @media (max-width: 768px) {
    min-width: 90%;
    margin: 0 auto;
    max-height: 250px;
    padding: 1rem;
    min-height: 250px;
  }
`;

const ReviewRating = styled.div`
  background: transparent;
  svg {
    width: 14px;
    height: 14px;
    background: transparent;
    color: gold;
  }
`;

const Description = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, #0f0f0f 10%, transparent);
  padding: 1rem;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-transform: lowercase;

  font-size: 0.85rem;
  line-height: 1.5;
  color: #fff;
`;

const CardBot = ({ date, title, description, link, img, tags, type, rating, status }: any) => {
  const click = (e: any) => {
    ReactGA.event({
      category: 'Button',
      label: type,
      value: title,
      action: `Clicked on ${title}`,
    });

    if (link.includes('netlify')) {
      e.preventDefault();
      window.open('/project/' + img.split('/')[1].split('.')[0], '_self');
      return;
    }
    e.preventDefault();
    window.open(link);
  };

  return (
    <CardBody background={require(`../../assets/${img}`)} onClick={click}>
      <Description className="card-bot">
        <h2>@{title}</h2>
        <ReviewRating>
          {Array.from({ length: rating }).map((_, i) => (
            <svg
              key={i}
              className="svg-inline--fa fa-star fa-w-18 final-star"
              aria-hidden="true"
              data-prefix="fa"
              data-icon="star"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
              ></path>
            </svg>
          ))}
        </ReviewRating>
        {description}
      </Description>
    </CardBody>
  );
};

export default CardBot;
