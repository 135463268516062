import React from 'react';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Fab from '../../components/Fab';
import Background from '../../components/Background';
import HeroSection from './HeroSection';
import AboutUs from './AboutUs';
import ReviewSlider from '../../components/ReviewSliderSwiper';
import FAQ from '../Bots/Faq';
import ImageSlider from '../../components/Slider';
import Work from './Work';
import ServiceSection from './ServiceSection';

export default function Home() {
  return (
    <div>
      <Background color="#000" slow={true} />
      <Navbar />
      <ImageSlider />
      <HeroSection />
      <ServiceSection />
      <AboutUs />
      <ReviewSlider />
      <Work />
      <FAQ />
      <Footer />
      <Fab />
    </div>
  );
}
