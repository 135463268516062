export const SERVICE: any = {
  website: {
    caption: 'Create Your Website',
    description:
      'Use our web development service to build a website that is both visually appealing and highly functional, with a focus on user experience.',
    src: 'web.webp',
    title: 'Web Development',
  },
  bot: {
    caption: 'Bot Development',
    description:
      'Develop a Telegram bot that can be used to automate a wide range of tasks, including customer support, marketing, and more.',
    src: 'bot.webp',
    title: 'Bot Development',
  },
  telegram_game: {
    caption: 'Make Your Telegram Game',
    description:
      'Create a Telegram game that is both entertaining and engaging, with a focus on user experience.',
    src: 'telegram_game.webp',
    title: 'Telegram Game Development',
  },
  game: {
    caption: 'Bring Your Game Idea to Life',
    description:
      'Create a 2D game that is both entertaining and engaging, with a focus on user experience.',
    src: 'game.webp',
    title: 'Game Development',
  },
  tiktok_game: {
    caption: 'Create Your TikTok Game',
    description:
      'Create a TikTok game that is both entertaining and engaging, with a focus on user experience.',
    src: 'tiktok_game.webp',
    title: 'TikTok Game Development',
  },
};

const GAME_SERVICE: any = {
  basic: {
    feature: ['7 Days Delivery', '1 Revision', 'Functional game', '1 level', 'Source code'],
    price: 250,
    description:
      'Shape a user-friendly 2D game with broad appeal, owing to its simplicity and entertainment value.',
  },
  standard: {
    feature: [
      '10 Days Delivery',
      '2 Revision',
      'Functional game',
      '8 level',
      'Source code',
      'Include design',
      'Animation',
    ],
    price: 500,
    description:
      'Create a top-tier 2D game featuring flawless gameplay mechanics and stunning visual effects.',
  },
  premium: {
    feature: [
      '14 Days Delivery',
      '3 Revision',
      'Functional game',
      '10 level',
      'Source code',
      'Include design',
      'Animation',
      'Multiplayer',
    ],
    price: 1200,
    description:
      'Forge a web and mobile multiplayer 2D game with impeccable functionality and captivating effects.',
  },
};

const TELEGRAM_SERVICE: any = {
  basic: {
    feature: ['3 Days Delivery', '1 Revision', '10 conversation steps', 'Conversation script'],
    price: 150,
    description: 'Simple bot A simple bot',
  },
  standard: {
    feature: [
      '4 Days Delivery',
      '2 Revision',
      'API integration',
      '50 conversation steps',
      'Conversation script',
    ],
    price: 500,
    description: 'Solid bot Complex bot that can do some complicated commands.',
  },
  premium: {
    feature: [
      '7 Days Delivery',
      '3 Revision',
      'API integration',
      '100 conversation steps',
      'Conversation script',
    ],
    price: 900,
    description:
      'Premium bot Advanced bot with advanced features , blockchain integration and more.',
  },
};

const WEB_SERVICE: any = {
  basic: {
    feature: ['5 Days Delivery', '1 Revision', '1 Page', 'Source code'],
    price: 150,
    description: 'Simple website A simple website',
  },
  standard: {
    feature: ['7 Days Delivery', '2 Revision', '5 Page', 'Source code'],
    price: 300,
    description: 'Solid website A solid website',
  },
  premium: {
    feature: ['10 Days Delivery', '3 Revision', '10 Page', 'Source code'],
    price: 500,
    description: 'Premium website A premium website',
  },
};

const TELEGRAM_GAME_SERVICE: any = {
  basic: {
    feature: ['5 Days Delivery', '1 Revision', 'Functional game', '1 level', 'Source code'],
    price: 200,
    description: 'Simple game 2D with simple graphics',
  },
  standard: {
    feature: [
      '10 Days Delivery',
      '2 Revision',
      'Functional game',
      '3 level',
      'Include design',
      'Source code',
    ],
    price: 600,
    description: 'Advanced Game with leaderboard',
  },
  premium: {
    feature: [
      '15 Days Delivery',
      '3 Revision',
      'Functional game',
      '6 level',
      'Include design',
      'Animation',
      'Source code',
    ],
    price: 1000,
    description: 'Advanced Game with leaderboard and multiplayer',
  },
};

export const TIKTOK_GAME_SERVICE: any = {
  basic: {
    feature: ['5 Days Delivery', '1 Revision', 'Functional game', '1 level', 'Source code'],
    price: 200,
    description: 'Simple game 2D with simple graphics',
  },
  standard: {
    feature: [
      '10 Days Delivery',
      '2 Revision',
      'Functional game',
      '3 level',
      'Include design',
      'Source code',
    ],
    price: 600,
    description: 'Advanced Game with leaderboard',
  },
  premium: {
    feature: [
      '15 Days Delivery',
      '3 Revision',
      'Functional game',
      '6 level',
      'Include design',
      'Animation',
      'Source code',
    ],
    price: 1000,
    description: 'Advanced Game with leaderboard and multiplayer',
  },
};

export const GAME_SERVICE_TITLE: any = {
  bot: 'We build bots for Discord, Telegram, and other platforms. ',
  game: 'We build games for Android, iOS, and Windows. We can build games for all genres.',
  website:
    'We build websites for all purposes. We can build websites for businesses, portfolios, and other purposes.',
};

export const SERVICE_DETAIL: any = {
  game: GAME_SERVICE,
  bot: TELEGRAM_SERVICE,
  website: WEB_SERVICE,
  telegram_game: TELEGRAM_GAME_SERVICE,
};
