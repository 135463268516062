import React from 'react';
import styled, { keyframes } from 'styled-components';

// Defining the animations
const stackAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
`;

const glitchAnimation = keyframes`
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%, 100% {
    text-shadow: none;
    transform: none;
  }
`;

// Styled components
const Container = styled.div<{ big: string }>`
  color: var(--color, black);
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: ${(props) => (props.big ? 'relative' : 'absolute')};
  animation: ${(props) => (props.big ? 'scale 1s ease-in-out infinite' : 'none')};
  cursor: pointer;

  left: 20px;

  //smaller screens
  @media (max-width: 768px) {
    height: 100px;
    position: relative;
    left: 0;
  }
`;

const Stack = styled.div<{ stacks: number }>`
  --stacks: ${(props) => props.stacks};
  display: grid;
  grid-template-columns: 1fr;
`;

const StackItem = styled.span<{ index: number }>`
  --index: ${(props) => props.index};
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 4rem;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(var(--stacks) - 1 - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: ${stackAnimation} 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    ${glitchAnimation} 2s ease infinite 2s alternate-reverse;

  &:nth-child(odd) {
    --glitch-translate: 8px;
  }
  &:nth-child(even) {
    --glitch-translate: -8px;
  }
`;

// SVG component placeholder, replace with your actual SVG
const SvgComponent = ({ big }: any) => (
  <svg
    width={big ? '313' : '100'}
    height={big ? '125' : '100'}
    viewBox="0 0 627 254"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.958 69.4141H22.6094V112.33H0V0H22.6094V47.9561H65.958V0H88.5674V112.33H65.958V69.4141Z"
      fill="#F2F6F9"
    />
    <path d="M124.501 7.2002L147.112 0V112.33H124.501V7.2002Z" fill="#F2F6F9" />
    <path
      d="M183.156 112.475V0H220.022C227.655 0 235.058 1.4541 242.028 4.33496C248.798 7.12792 254.874 11.1465 260.073 16.2588C265.302 21.4004 269.419 27.4199 272.286 34.1309C275.253 41.0869 276.765 48.5029 276.765 56.165C276.765 63.8262 275.253 71.2432 272.286 78.2129C269.419 84.9395 265.315 90.959 260.087 96.127C254.874 101.27 248.812 105.303 242.042 108.111C235.073 111.006 227.655 112.475 220.022 112.475L183.156 112.475ZM220.022 89.8633C229.196 89.8633 237.78 86.3789 244.218 80.0273C250.626 73.7051 254.153 65.2227 254.153 56.165C254.153 47.1211 250.626 38.6816 244.231 32.3887C237.81 26.0801 229.212 22.6094 220.022 22.6094H205.766V89.8633H220.022Z"
      fill="#F2F6F9"
    />
    <path
      d="M353.383 0H333.898L291.343 112.33H315.277L343.605 34.4619L372.178 112.33H396.111L353.383 0Z"
      fill="#F2F6F9"
    />
    <path d="M343.803 112.33L325.924 84.6508H361.53L343.803 112.33Z" fill="#1E88E5" />
    <path
      d="M442.641 42.1807V112.329H420.031V0H440.063L490.309 70.7959V0H512.92V112.329H492.455L442.641 42.1807Z"
      fill="#F2F6F9"
    />
    <path
      d="M543.849 95.8105L593.95 21.458H545.145L560.987 0H624.784V16.5322L574.206 90.8711H626.8L610.958 112.33H543.849V95.8105Z"
      fill="#F2F6F9"
    />
    <path
      d="M0 253.055V140.58H36.8662C44.499 140.58 51.9014 142.034 58.8721 144.915C65.6416 147.708 71.7178 151.726 76.917 156.839C82.1455 161.98 86.2627 168 89.1299 174.711C92.0967 181.667 93.6084 189.083 93.6084 196.745C93.6084 204.406 92.0967 211.823 89.1299 218.793C86.2627 225.519 82.1592 231.539 76.9307 236.707C71.7178 241.85 65.6553 245.883 58.8858 248.691C51.917 251.586 44.499 253.055 36.8662 253.055L0 253.055ZM36.8662 230.443C46.04 230.443 54.624 226.959 61.0615 220.607C67.4697 214.285 70.9971 205.803 70.9971 196.745C70.9971 187.701 67.4697 179.262 61.0752 172.969C54.6533 166.66 46.0557 163.189 36.8662 163.189H22.6094V230.443H36.8662Z"
      fill="#F2F6F9"
    />
    <path
      d="M124.625 252.91H197.351V231.883H124.625V252.91ZM124.625 207.689H197.351V186.231H124.625V207.689ZM124.625 140.58V162.038H197.351V140.58H124.625Z"
      fill="#F2F6F9"
    />
    <path
      d="M237.998 172.692L256.143 219.208L274.145 172.692H297.619L265.072 252.907H246.927L214.38 172.692H237.998Z"
      fill="#F2F6F9"
    />
  </svg>
);

// React component
const LogoGlitch: any = (props: any) => {
  const stacks = 8; // Define the number of stacks here
  const { big, click } = props;
  return (
    <Container big={big} onClick={() => click && click()}>
      <Stack stacks={stacks}>
        {Array.from({ length: stacks }, (_, index) => (
          <StackItem key={index} index={index}>
            <SvgComponent big={big} />
          </StackItem>
        ))}
      </Stack>
    </Container>
  );
};

export default LogoGlitch;
