import React from 'react';
import 'react-awesome-button/dist/styles.css';
import './index.css';
import { AwesomeButton } from 'react-awesome-button';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
const AwsomeButtonChanged = styled.div<any>`
  height: ${({ size }) => (size === 'large' ? '50px' : '40px')};
  max-width: ${({ size }) => (size === 'large' ? '250px' : '150px')};
  font-size: ${({ size }) => (size === 'large' ? '16px' : '14px')};
  background: transparent !important;
  width: 100%;
  span {
    background: transparent;
  }
  @media (max-width: 768px) {
    font-size: ${({ size }) => (size === 'large' ? '12px' : '12px')};
    max-width: ${({ size }) => (size === 'large' ? '150px' : '200px')};
  }
`;

export default function Button(props: any) {
  const { size } = props;

  return (
    <AwsomeButtonChanged
      onClick={() => {
        ReactGA.event({
          category: 'Click',
          action: `Clicked on ${props.children}`,
        });

        props.onClick();
      }}
      style={{
        ...props.style,
      }}
    >
      <AwesomeButton
        size={size}
        {...props}
        style={{
          width: '100%',
        }}
      >
        {props.children}
      </AwesomeButton>
    </AwsomeButtonChanged>
  );
}
