import React from 'react';
import { styled } from 'styled-components';
import CardBot from '../../components/CardBot';
import { BOTS } from '../../api/BotsData';
import { WEBSITES } from '../../api/WebsitesData';
import { GAMES } from '../../api/GamesData';
import ReactGA from 'react-ga4';

const Container = styled.div`
  padding: 20px;
  text-align: center;
  min-height: 100vh;
  background-position: top;
  max-width: 1300px;
  margin: 0 auto;

  //smaller screens
  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;
  }
`;

const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0;
  margin-right: auto;
  margin-bottom: 25px;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  font-weight: 400;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
  margin-bottom: 100px;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.1rem;
    width: 100%;
  }
`;

const ContainerInner = styled.div`
  text-align: left;

  //smaller screens
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const CardBots = styled.div`
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
  gap: 20px;
  text-align: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    gap: 20px;
    width: 100%;
  }
`;

const ClickForMore = styled.a`
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 auto;
  line-height: 1.5;
  display: block;
  margin-top: 100px;
  padding: 10px 20px;
  border-radius: 15px;
  max-width: 400px;
  text-decoration: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #ffffff10;
  color: #fff;
  &:hover {
    background: linear-gradient(90deg, #448ccc, #62c3fd);
  }

  //smaller screens
  @media (max-width: 768px) {
    font-size: 0.8rem;
    width: 100%;
  }
`;

export default function Work(props: any) {
  const { service } = props;

  const [list, setList] = React.useState(
    BOTS.concat(GAMES)
      .concat(WEBSITES)
      .sort(() => Math.random() - 0.5)
  );

  React.useEffect(() => {
    if (service === 'game') {
      setList(GAMES);
    } else if (service === 'bot') {
      setList(BOTS);
    } else if (service === 'web') {
      setList(WEBSITES);
    }
  }, [service]);

  return (
    <Container>
      <ContainerInner>
        <Title>
          {service === 'bot'
            ? 'Bots'
            : service === 'game'
            ? 'Games'
            : service === 'website'
            ? 'Websites'
            : 'Telegram games'}{' '}
          That We Have Made
        </Title>
        <Description>
          We have a wide range of{' '}
          {service === 'bot'
            ? 'bots'
            : service === 'game'
            ? 'games'
            : service === 'website'
            ? 'websites'
            : 'Telegram games'}{' '}
          that we have, and are currently working on.
        </Description>

        <CardBots>
          {list.slice(0, window.innerWidth < 768 ? 3 : 8).map((bot) => (
            <CardBot key={bot.id + bot.title} {...bot} />
          ))}
        </CardBots>
      </ContainerInner>
      <ClickForMore
        href={
          service === 'game'
            ? '/work?service=game'
            : service === 'bot'
            ? '/work?service=bot'
            : service === 'website'
            ? '/work?service=web'
            : '/work?service=telegram_games'
        }
        onClick={() => {
          ReactGA.event({
            category: 'Click',
            action: 'Click for more projects',
          });
        }}
      >
        Click here to see more{' '}
        {service === 'bot'
          ? 'bots'
          : service === 'game'
          ? 'games'
          : service === 'website'
          ? 'websites'
          : 'telegram_games'}
      </ClickForMore>
    </Container>
  );
}
