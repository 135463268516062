import React from 'react';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Fab from '../../../components/Fab';
import Background from '../../../components/Background';
import Work from './Work';

export default function AllWorks() {
  return (
    <div>
      <Background />
      <Navbar />
      <Work />
      <Footer />
      <Fab />
    </div>
  );
}
